<template>
  <div>
    <h3>Список отображаемых постов</h3>
    <div style="overflow-y: auto; max-height: 55vh; background-color: white; padding-top: 3px">
      <div v-show="!allGauges || !allGauges.length" style="width: 100%; text-align: center">Загрузка...</div>
      <div v-for="gauge in allGauges" :key="gauge.code" style="padding: 0 10px; border: 1px solid gray; margin-bottom: 4px" class="gaugeElem">
        <md-checkbox v-model="visibleGauges" :value="gauge.code">{{gauge.name}} ({{gauge.latitude}}&#176; c.ш. {{gauge.longitude}}&#176; в.д.)</md-checkbox>
      </div>
    </div>
    <div style="display: flex; padding: 10px 0; justify-content: space-between">
      <div style="display: flex; gap: 20px;">
        <div style="cursor: pointer;  " >
          <input type="button" value="Выделить все" @click="setAll" class="dialog-button"/></div>
        <div style="cursor: pointer;  " >
          <input type="button" value="Снять все" @click="unsetAll"  class="dialog-button"/></div>
      </div>
      <div >
        <div style="cursor: pointer; align-self: flex-end; margin-right: 0" >
          <input type="button" value="Ок" @click="ok"  class="dialog-button"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'GaugeSettingForm',
    props: {
      allGauges: {
        type: Array,
        default: () => {
          return []
        }
      },
      selectedCodes: {
        type: Array,
        default: () => {
          return []
        }
      },
    },
    data() {
      return {
        visibleGauges: this.allGauges.filter(g => this.selectedCodes.includes(g.code)).map(g => g.code)
      }
    },
    methods: {
      setAll() {
        this.visibleGauges = this.allGauges.map(g => g.code)
      },
      unsetAll() {
        this.visibleGauges = []
      },
      ok() {
        console.log(this.visibleGauges)
        this.$emit('onGaugesApply', this.visibleGauges)
        this.$emit('closeGaugeSettingsForm')
      }
    }
  }
</script>

<style>
  .gaugeElem:hover {
    background-color: #9acfea;
    border-color: #2aabd2 !important;
  }

  .dialog-button {
    cursor: pointer;
    width: 120px;
  }
</style>