<template>
  <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%; height: 100%">
    <div><h4>Текущие данные о море</h4></div>
    <!--status line-->

    <md-dialog :md-active.sync="showDataSettings"
               :before-close="() => showDataSettings = false"
    >
      <md-dialog-content >
        <md-dialog-actions>
          <md-button class="md-primary" @click="showDataSettings = false">Закрыть</md-button>
        </md-dialog-actions>
        <data-settings-form  @closeDataSettingsForm="handleCloseDataSettingsform" @applyDataSettings="handleDataSettings" :chart-settings="chartSettings"/>
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync="showGaugeSettings"
               :before-close="() => showGaugeSettings = false"

    >
      <md-dialog-content style="padding: 10px; background-color: lightgray">
        <md-dialog-actions>
          <md-button class="md-primary" @click="showGaugeSettings = false">Закрыть</md-button>
        </md-dialog-actions>
        <gauge-settings-form  @closeGaugeSettingsForm="handleCloseGaugeSettingsform" :all-gauges="allGauges" @onGaugesApply="handleGaugesChange" :selectedCodes="visibleGaugesCodes"/>
      </md-dialog-content>
    </md-dialog>

    <div style="display: flex; align-items: baseline; justify-content:  space-between; width: 100%; padding: 10px; flex-wrap: wrap; gap: 10px 10px" >
      <div style="cursor: pointer; " @click="sortByWaveDate()"><input type="button"  value="Отсортировать по времени добегания" /></div>
      <div><h4>{{status}}</h4></div>
      <div style="display: flex; gap: 20px">
        <div style="cursor: pointer;  " ><input type="button" value="Настройки" @click="showDataSettings = true" /></div>
        <div style="cursor: pointer;  " ><input type="button" :value="'Список постов - ' + visibleGaugesCodes.length" @click="showGaugeSettings = true" /></div>
        <div style="cursor: pointer;  " @click="fullRefresh"><input type="button" value="Обновить" /></div>
      </div>
    </div>
    <div style="" class="chart-data-container">
      <PointData v-for="gauge in visibleGauges" :key="gauge.code + loadDate" :gauge="gauge" @hideGauge="hideGauge" @onDown="handleOnDown" @onUp="handleOnUp" :chart-settings="chartSettings" :wave-time="getWaveTime(gauge)" :wave-height="getWaveHeight(gauge)"/>
    </div>
    <!--<div><a href="#" @click="returnBack"><h4>вернуться на страницу входа</h4></a></div>-->
  </div>
</template>

<script>
  import PointData from "../components/base/PointData";
  import GaugeSettingsForm from "../components/base/GaugeSettingsForm"
  import DataSettingsForm from "../components/base/DataSettingsForm"
  import {getGauges} from "../api/gauge-controller";
  import {getItem, setItem} from "../utils/persistanceStorage";
  import appConfig from "../appConfig";
  import {mapGetters} from "vuex";
  import {signalToText} from "../utils/custom";
  import {getResults} from "../api/result-controller";

  export default {
    name: 'SeaData',
    components: {PointData, GaugeSettingsForm, DataSettingsForm},
    data() {
      return {
        signalToShow: null,
        chartSettings: null,
        loadDate: null,
        status: 'Не задано',
        results: [],
        shortList: [],
        allGauges: [],
        visibleGaugesCodes: [],
        filteredGauges: [],
        showDataSettings: false,
        showGaugeSettings: false,
        updateInterval: null
      }
    },
    computed: {
      ...mapGetters(['getFirstSignal']),
      visibleGauges() {
        return this.allGauges.filter(g => this.visibleGaugesCodes.includes(g.code))
      }
    },
    methods: {
      sortByWaveDate() {
        let that = this
        this.allGauges.sort((a, b) => {
          let aTime = that.getWaveTime(a)
          let bTime = that.getWaveTime(b)
          if (!aTime && !bTime) return 0;
          if (!aTime) return -1;
          if (!bTime) return 1;

          return aTime - bTime
        })
      },
      handleDataSettings(data) {
        // console.log('handleDataSettings-end', data)
        setItem('chartSettings', data)
        // console.log('saved', JSON.stringify(getItem('chartSettings')))
        this.chartSettings = data
      },
      handleOnDown(code) {
        let index = this.allGauges.findIndex(g => g.code === code)
        if (index >= 0 && index < this.allGauges.length) {
          let temp = this.allGauges[index + 1]
          this.$set(this.allGauges, index + 1, this.allGauges[index])
          this.$set(this.allGauges, index, temp)
        }
      },
      handleOnUp(code) {
        let index = this.allGauges.findIndex(g => g.code === code)
        if (index > 0 ) {
          let temp = this.allGauges[index - 1]
          this.$set(this.allGauges, index - 1, this.allGauges[index])
          this.$set(this.allGauges, index, temp)
        }
      },
      returnBack() {
        this.$router.push({path: '/'})
      },
      getWaveTime(gauge) {
        if (!this.shortList || !this.shortList.length) return null;
        let found = null
        // console.log('short results 2', this.shortList.map(el => Object.values(el)).join(', '))
        // for (let item in this.shortList) {
        let i = 0
        while (this.shortList && this.shortList.length && i < this.shortList.length && !found) {
          let item = this.shortList[i];
          if (item) {
            // console.log('test', 'short', item.name, 'gauge', gauge.name, gauge.longitude, item.longitude, gauge.longitude == item.longitude, gauge.name.startsWith(item.name))
            // if (gauge.longitude == item.longitude && gauge.latitude == item.latitude) { //ignore
            if (gauge.name.startsWith(item.name)) {
              console.log('found', item, gauge.name)
              found = item.time
            }
          }
          i++;
        }
        console.log('getWaveTime', found)
        return found
      },
      getWaveHeight(gauge) {
        return gauge
      },
      async fullRefresh() {
        await this.loadGauges();
        await this.refresh();
      },
      async refresh() {
        this.signalToShow = this.getFirstSignal
        if (this.signalToShow) {
          this.status = signalToText(this.signalToShow)
          let resp = await getResults(this.signalToShow.id);
          this.results = resp.payload ? resp.payload : []
          if (this.results.length) {
            this.shortList = []
            this.results.forEach(el => {
              if (el.results) {
                this.shortList.push(...el.results)
              }
            })
            // console.log('short results', this.shortList.map(el => Object.keys(el)).join(', '))
          }
        }
        // this.loadGauges()
      },
      async loadGauges() {
        let results = await getGauges()
        this.allGauges =  results.payload ? results.payload : []
        this.loadDate = new Date()

      },
      handleCloseDataSettingsform() {
        this.showDataSettings = false
      },
      handleCloseGaugeSettingsform() {
        this.showGaugeSettings = false
      },
      handleGaugesChange(gaugesCodes) {
        this.visibleGaugesCodes = gaugesCodes
      },
      hideGauge(gauge) {
        let index = this.visibleGaugesCodes.indexOf(gauge.code)
        if (index >= 0) {
          this.visibleGaugesCodes.splice(index, 1)
        }
      }
    },
    async mounted() {
      await this.loadGauges()
      await this.refresh()
      // this.visibleGaugesCodes = this.allGauges.map(g => g.code)
      if (getItem('chartSettings')) {
        this.chartSettings = getItem('chartSettings')
      } else {
        this.chartSettings = appConfig.chartSettings
      }
      let that = this
      this.updateInterval = setInterval(() => {
        that.refresh()
      }, 20000)
    },
    destroyed() {
      if (this.updateInterval) {
        clearInterval(this.updateInterval)
      }
    }
  }
</script>

<style>


  @media (min-width: 769px) {
    .chart-data-container {
      width: 100%;
      display: flex; flex-direction: column; gap: 20px; max-height: 60vh; overflow-y: auto; min-height: 65vh
    }
  }
  @media (max-width: 768px) {
    .chart-data-container {
      display: flex; flex-direction: column;
    }
  }

  /*h4 {*/
    /*margin: 0 !important;*/
  /*}*/
</style>
